import Vue from 'vue';
import VueRouter from 'vue-router';
import { mainRoutes } from '@main/routes';
import NotFoundPage from '../components/NotFoundPage.vue';

Vue.use(VueRouter);

const routes = [
  ...mainRoutes,
  { path: '*', component: NotFoundPage }
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
});
const allRoutes = router.getRoutes().map((route) => route.name);
router.beforeResolve((to, from, next) => {
  if (allRoutes.includes(to.name)) {
    next();
  } else {
    next('/404');
  }
});

export { router };
