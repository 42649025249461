import Main from './Main.vue';
import Front from './views/Front.vue';
import Certifications from './views/Certifications.vue';
import About from './views/About.vue';
import Contact from './views/Contact.vue';

export const mainRoutes = [
  {
    path: '/',
    component: Main,
    children: [
      {
        path: '',
        component: Front,
        name: 'front'
      },
      {
        path: '/certifications',
        component: Certifications,
        name: 'certifications'
      },
      {
        path: '/about',
        component: About,
        name: 'about'
      },
      {
        path: '/contact',
        component: Contact,
        name: 'contact'
      }
    ]
  }];
