<template>
    <div class="box-maker d-flex flex-column flex-md-row justify-content-around align-items-center pt-5 flex-grow-1 flex-md-wrap">
      <div v-for="(box, index) in boxes"
      :key="index"
      class="widget widget-width d-flex flex-column"
      :style="widgetStyle">
        <div class="subtitle">{{box.title}}</div>
        <div class="inner-box">{{box.text}}</div>
      </div>
    </div>
</template>
<script>
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';

@Component
export default class BoxMaker extends Vue {
    @Prop({ type: Array, required: true, default: () => ([]) }) boxes;

    @Prop({ type: String, required: false, default: '' }) border;

    @Prop({ type: String, required: false, default: '' }) background;

    @Prop({ type: String, required: false, default: '30%' }) desktopWidth;

    @Prop({ type: String, required: false, default: '70%' }) mobileWidth;

    @Prop({ type: String, required: false, default: '' }) boxStyle;

    @Prop({ type: String, required: false, default: '80%' }) boxHeight;

    get widgetStyle() {
      let res = '';
      if (this.border && this.border.length > 0) {
        res += `border: ${this.border};`;
      }
      if (this.background && this.background.length > 0) {
        res += `background: ${this.background};`;
      }
      res += `--desktop-widget-width: ${this.desktopWidth}; --mobile-widget-width: ${this.mobileWidth};`;
      res += `--widget-height: ${this.boxHeight};`;
      res += this.boxStyle;
      return res;
    }

    static getBoxes(messages, translationFunction) {
    // eslint-disable-next-line no-underscore-dangle
      const translations = Object.entries(messages)// [[], []]
      // eslint-disable-next-line no-unexpected-multiline
        [0]// ['en', {messages}]
      // eslint-disable-next-line no-unexpected-multiline
        [1];// {key: translation,...}
      const translationKeys = Object.keys(translations);
      const boxes = new Set(translationKeys.filter((key) => key.startsWith('box')).map((boxKey) => boxKey.split('_')[0]));
      const boxesTranslated = [...boxes].map((box) => ({ title: translationFunction(`${box}_title`), text: translationFunction(`${box}_text`) }));
      return boxesTranslated;
    }
}
</script>
<style scoped>
.widget-width {
  width: var(--desktop-widget-width ,30%);
}
.widget {
  height: var(--widget-height, 80%);
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: flex-start;
}
@media screen and (max-width: 640px) {
  .widget-width {
    width: var(--mobile-widget-width ,70%);
  }
}
.inner-box{
  white-space: pre-line;
}
</style>
