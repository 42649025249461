<i18n>
{
  "en": {
    "front": "Home",
    "contact": "Contact",
    "about": "About",
    "certifications": "Certifications",
    "title": "Susana Ayala Díez"
  },
  "es": {
    "front": "Inicio",
    "contact": "Contacto",
    "about": "Acerca de",
    "certifications": "Acreditaciones",
    "title": "Susana Ayala Díez"
  }
}
</i18n>
<template>
    <div class="pt-3">
        <side-bar :buttons="buttons" :component="sideBarComponent" :isMainPage="isMainPage" :title="headerTitle"
        class="d-md-none" @go-to="onGoTo"/>
        <desktop-header :buttons="buttons" :title="headerTitle"
        class="d-none d-md-block" @go-to="onGoTo"/>
    </div>
</template>
<script>
import Component from 'vue-class-component';
import Vue from 'vue';
import { Components } from '@core/helpers/decorators';
import SideBarButton from './SideBarButton.vue';
import SideBar from './SideBar.vue';
import DesktopHeader from './DesktopHeader.vue';

@Component
@Components({ SideBar, DesktopHeader })
export default class FullHeader extends Vue {
  get buttons() {
    return [
      {
        name: this.$t('front'),
        route: { name: 'front' }
      },
      {
        name: this.$t('certifications'),
        route: { name: 'certifications' }
      },
      {
        name: this.$t('about'),
        route: { name: 'about' }
      },
      {
        name: this.$t('contact'),
        route: { name: 'contact' }
      }
    ];
  }

  get isMainPage() {
    return this.$route.name === 'front';
  }

  get headerTitle() {
    return this.$t('title');
  }

  get sideBarComponent() {
    return SideBarButton;
  }

  onGoTo(route) {
    this.$router.push(route).catch(() => {});
  }
}
</script>
<style>
.header-item {
  text-transform: uppercase;
}
.header-item:hover{
  cursor: pointer;
}
</style>
