<template>
    <iframe
      scrolling="no"
      marginheight="0"
      marginwidth="0"
      src="https://maps.google.com/maps?width=100%25&amp;height=100%&amp;hl=es&amp;q=Calle%2020%20de%20Febrero%205,%205%C2%BAB,%20Valladolid+(Susana%20Garc%C3%ADa%20Ayala%20Abogados)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
      width="100%"
      height="100%"
      frameborder="0"
    >
    </iframe>
</template>
<script>
import Component from 'vue-class-component';
import Vue from 'vue';

@Component
export default class Maps extends Vue {
}
</script>
