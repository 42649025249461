<template>
  <div class="image-text-container" v-show="!loading">
    <b-img ref="image" class="image" :src="imagePath" fluid :width="width" />
    <div ref="text" class="text-container" :style="textContainerStyle">
      {{ text }}
    </div>
  </div>
</template>

<script>
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';

@Component
export default class Certifications extends Vue {
  @Prop({ type: String, required: true }) imagePath;

  @Prop({ type: String, required: true }) text;

  @Prop({ type: String, required: false, default: '' }) textStyle;

  @Prop({ type: Number, required: false, default: 150 }) width;

  @Prop({ type: Number, required: false, default: 0 }) top;

  @Prop({ type: Number, required: false, default: 0 }) left;

  loading=true;

  mounted() {
    if (this.$refs.image.complete) {
      this.setLoading();
    } else {
      this.$refs.image.addEventListener('load', this.setLoading);
    }
  }

  setLoading() {
    this.loading = !this.loading;
  }

  get textContainerStyle() {
    return `--image-text-width: ${this.width}px;--image-text-top: ${this.top}px; --image-text-left: ${this.left}px;${this.textStyle}`;
  }
}
</script>
<style scoped>
.text-container {
  position: absolute;
  word-wrap: break-word;
  top: var(--image-text-top, 0px);
  left: var(--image-text-left, 0px);
  display: inline-block;
  width: 100%;
}
.image {
  z-index: -1;
}
.image-text-container {
  position: relative;
  box-sizing: content-box;
}
</style>
