<i18n>
{
    "en": {
        "name": "Susana Ayala Díez",
        "title": "Lawyers & Mediators",
        "subtitle": "Confict Resolution",
        "box1_title": "To highlight",
        "box1_text": "We are specialized in mediation, family law, and we also have great experience and success in criminal law, penitentiary law and gender violence.\n\nWe put special emphasis on people and their situations, that is why we contribute to the public defender's office in these specialties as far as possible.",
        "box2_title": "Closeness",
        "box2_text": "Based in Valladolid, we pride ourselves in offering a close, transparent and committed treatment with our clients' problems, always looking for the best solution for them."
    },
    "es": {
        "name": "Susana Ayala Díez",
        "title": "Abogados & Mediadores",
        "subtitle": "Resolución de conflictos",
        "box1_title": "Destacamos",
        "box1_text": "Estamos especializados en mediación, derecho de familia, y además tenemos gran experiencia y éxito en derecho penal, penitenciario y violencia de género.\n\nPonemos especial énfasis en las personas y sus situaciones, por eso contribuímos al turno de Oficio de estas especialidades en la medida de lo posible.",
        "box2_title": "Cercanía",
        "box2_text": "Con sede en Valladolid, nos enorgullecemos de ofrecer un trato cercano, transparente y comprometido con los problemas de nuestros clientes, buscando siempre la mejor solución para ellos."
    }
}
</i18n>
<template>
  <div class="d-flex flex-column">
    <div class="front-title">
      <div class="name">
        {{ $t("name") }}
      </div>
      <div class="title">
        {{ $t("title") }}
      </div>
      <div class="subtitle">
        {{ $t("subtitle") }}
      </div>
    </div>
    <box-maker :boxes="boxes"
      border="solid 2px rgba(var(--main-color), 1);"
      background="white;"
      box-height="70%"
      box-style="padding-right: 5px; padding-left: 5px; justify-content: center;"/>
  </div>
</template>
<script>
import Component from 'vue-class-component';
import Vue from 'vue';
import { Components } from '@core/helpers/decorators';
import BoxMaker from '../components/BoxMaker.vue';

@Component
@Components({ BoxMaker })
export default class Front extends Vue {
  get boxes() {
    // eslint-disable-next-line no-underscore-dangle
    const { messages } = this._i18n;
    const translationFunction = this.$t.bind(this);
    return BoxMaker.getBoxes(messages, translationFunction);
  }
}
</script>
