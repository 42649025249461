export class Rectangle {
  constructor(top, left, height, width) {
    this.top = top;
    this.left = left;
    this.height = height;
    this.width = width;
    this.extraStyle = {};
  }

  setStyle(extraStyle) {
    this.extraStyle = extraStyle;
  }

  get style() {
    return {
      top: `${this.top}px`,
      left: `${this.left}px`,
      width: `${this.width}px`,
      height: `${this.height}px`,
      ...this.extraStyle
    };
  }

  get area() {
    return this.width * this.height;
  }

  get y1() {
    return this.top;
  }

  get y2() {
    return this.top + this.height;
  }

  get x1() {
    return this.left;
  }

  get x2() {
    return this.left + this.width;
  }

  overlapRatio(rectangle) {
    const area = this.overlap(rectangle);
    return this.area >= rectangle.area ? area / this.area : area / rectangle.area;
  }

  overlap(rectangle) {
    return this.overlapY(rectangle) * this.overlapX(rectangle);
  }

  overlapY(rectangle) {
    const minPoint = Math.min(this.y1, this.y2, rectangle.y1, rectangle.y2);
    const maxPoint = Math.max(this.y1, this.y2, rectangle.y1, rectangle.y2);
    const proyectedLength = maxPoint - minPoint;
    const totalLength = this.height + rectangle.height;
    if (totalLength > proyectedLength) {
      return totalLength - proyectedLength;
    }
    return 0;
  }

  overlapX(rectangle) {
    const minPoint = Math.min(this.x1, this.x2, rectangle.x1, rectangle.x2);
    const maxPoint = Math.max(this.x1, this.x2, rectangle.x1, rectangle.x2);
    const proyectedLength = maxPoint - minPoint;
    const totalLength = this.width + rectangle.width;
    if (totalLength > proyectedLength) {
      return totalLength - proyectedLength;
    }
    return 0;
  }
}
