<template>
  <div>
    <b-button
      v-b-toggle.sidebar-backdrop
      class="sidebar-button"
      variant="light"
      size="lg"
      ><i class="fas fa-bars"></i
    ></b-button>
    <div class="d-flex justify-content-center align-items-center flex-column pb-3">
      <b-img src="../../../assets/Logo.svg" fluid width="150" class="logo" />
      <div v-if="!isMainPage" class="title">{{ title }}</div>
    </div>
    <b-sidebar
      id="sidebar-backdrop"
      no-header
      backdrop
      backdrop-variant="dark"
      shadow
      width="230px"
    >
      <div class="d-flex flex-column align-items-start pl-3 pt-3">
        <component
          v-for="(button, index) in buttons"
          :key="index"
          :is="component"
          :name="button.name"
          :route="button.route"
          :class="{ selected: selected(button.route) }"
          v-on="$listeners"
        />
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';

@Component
export default class SideBar extends Vue {
  @Prop({
    type: Array,
    default: () => [],
    validator: (value) => value.every((element) => 'name' in element && 'route' in element),
  })
  buttons;

  @Prop({ type: Object }) component;

  @Prop({ type: Boolean, default: true }) isMainPage;

  @Prop({ type: String, default: '' }) title;

  selected(route) {
    return route.name === this.$route.name;
  }
}
</script>
<style scoped>
.sidebar-button {
  position: fixed;
  top: 0;
  left: 0;
}
</style>
