<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <b-img src="../../../assets/Logo.svg" fluid width="150" class="logo" />
        <div class="header-title">{{ title }}</div>
      </div>

      <div
        class="d-flex flex-row justify-content-center desktop-header-buttons"
      >
        <div
          v-for="(button, index) in buttons"
          :key="index"
          @click="onClick(button.route)"
          class="desktop-header-item header-item px-3"
          :class="{ 'selected': selected(button.route) }"
        >
          {{ button.name }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Vue from 'vue';

@Component
export default class DesktopHeader extends Vue {
  @Prop({
    type: Array,
    default: () => [],
    validator: (value) => value.every((element) => 'name' in element && 'route' in element),
  })
  buttons;

  @Prop({ type: String, default: '' }) title;

  onClick(route) {
    this.$emit('go-to', route);
  }

  selected(route) {
    return route.name === this.$route.name;
  }
}
</script>
<style scoped>
.desktop-header-item {
  font-size: 20px;
}
.desktop-header-buttons {
  padding-right: 10%;
}
.logo {
  margin-left: 15%;
}
.header-title {
  min-width: 30%;
}
.selected {
  text-decoration: underline;
  text-decoration-color: rgba(var(--main-color), 1);
  text-underline-offset: 5px;
}
</style>
