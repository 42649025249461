<template>
  <div class="h-100 d-flex flex-column">
    <full-header/>
      <router-view class="flex-grow-1"/>
      <background-maker/>
  </div>
</template>
<script>
import Component from 'vue-class-component';
import Vue from 'vue';
import { Components } from '@core/helpers/decorators';
import FullHeader from './components/FullHeader.vue';
import BackgroundMaker from './components/BackgroundMaker.vue';

@Component
@Components({ FullHeader, BackgroundMaker })
export default class Main extends Vue {

}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&family=Lato&family=Montserrat&display=swap');
div,span{
  font-family: 'Montserrat', sans-serif;
}
.title {
  font-family: "EB Garamond", serif;
  font-size: 3em;
}
.name {
  font-family: "EB Garamond", serif;
  font-size: 4em;
  padding-bottom: 10px;
}
.subtitle {
  font-family: "Lato", sans-serif;
  font-size: 2em;
}

@media screen and (max-width: 640px) {
  .title {
    font-size: 26px;
  }
  .name {
    font-size: 32px;
  }
  .subtitle {
    font-size: 20px;
  }
}
</style>
