<template>
  <div class="position-absolute h-100 w-100 background">
    <transition
      v-for="(rectangle, index) in rectangles"
      :key="index"
      name="fade"
      appear
    >
      <div :style="rectangle.style" class="position-absolute"></div>
    </transition>
  </div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Rectangle } from '../models';

@Component
export default class BackgroundMaker extends Vue {
  rectWidthScalingFactor = 0.4;

  widthOffSetFactor = 0.05;

  rectMinWidthFactor = 0.2;

  rectHeightScalingFactor = 0.4;

  heightOffSetFactor = 0.05;

  rectMinHeigthFactor = 0.2;

  numRectangles = 6;

  aspectRatioThreshold = 4 / 3;

  aspectRatioBoth = true;

  isMounted = false;

  minOverlapping = 0;

  minOverlappingNum = 0;

  maxOverlapping = 0.3;

  maxOverlappingNum = 1;

  created() {
    window.addEventListener('resize', this.onResize);
  }

  mounted() {
    this.isMounted = true;
  }

  onResize() {
    this.isMounted = false;
    this.$nextTick(() => {
      this.isMounted = true;
    });
  }

  get rectangles() {
    if (this.isMounted) {
      const rects = [];
      for (let index = 0; index < this.numRectangles; index += 1) {
        rects.push(this.getRectangle(rects));
      }
      return rects;
    }
    return [];
  }

  setRectStyle(rectangle) {
    rectangle.setStyle({
      border: 'solid 2px rgba(var(--main-color), 0.10)',
      background: 'rgb(255 255 255 / 60%)',
    });
  }

  getRectangle(rectangles) {
    let top;
    let left;
    let width;
    let height;
    let rectangle;
    do {
      top = this.getRandomInt(this.heightOffSetFactor, this.maxHeigh);
      left = this.getRandomInt(this.widthOffSetFactor, this.maxWidth);
      width = this.getRandomInt(
        this.maxWidth * this.rectMinWidthFactor,
        this.maxWidth * this.rectWidthScalingFactor
      );
      height = this.getRandomInt(
        this.maxHeigh * this.rectMinHeigthFactor,
        this.maxHeigh * this.rectHeightScalingFactor
      );
      rectangle = new Rectangle(top, left, height, width);
    } while (!this.isValidRectangle(rectangle, rectangles));
    this.setRectStyle(rectangle);
    return rectangle;
  }

  isValidRectangle(rectangle, rectangles) {
    return (
      this.isInside(rectangle)
      && this.isValidAspectRatio(rectangle)
      && this.isValidOverlapping(rectangle, rectangles)
    );
  }

  isInside(rectangle) {
    const widthLimit = rectangle.left + rectangle.width;
    const heightLimit = rectangle.top + rectangle.height;
    return widthLimit <= this.maxWidth && heightLimit <= this.maxHeigh;
  }

  isValidAspectRatio(rectangle) {
    const aspectRatio = rectangle.width / rectangle.height;
    return (
      aspectRatio <= this.aspectRatioThreshold
      && (!this.aspectRatioBoth || 1 / aspectRatio <= this.aspectRatioThreshold)
    );
  }

  isValidOverlapping(rectangle, rectangles) {
    if (rectangles.length === 0) {
      return true;
    }
    const ratios = rectangles.map((rect) => rectangle.overlapRatio(rect));
    const invalidMinRatio = ratios.some((ratio) => ratio < this.minOverlapping);
    const invalidMaxRatio = ratios.some((ratio) => ratio > this.maxOverlapping);
    const numOverlapping = ratios.reduce(
      (acc, curr) => (curr === 0 ? acc : acc + 1),
      0
    );
    return (
      !invalidMinRatio
      && !invalidMaxRatio
      && numOverlapping >= this.minOverlappingNum
      && numOverlapping <= this.maxOverlappingNum
    );
  }

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    const number = Math.floor(Math.random() * (max - min + 1)) + min;
    return number;
  }

  get maxWidth() {
    if (this.isMounted) {
      return this.$el.clientWidth;
    }
    return 0;
  }

  get maxHeigh() {
    if (this.isMounted) {
      return this.$el.clientHeight;
    }
    return 0;
  }
}
</script>
<style scoped>
.background {
  top: 0;
  left: 0;
  z-index: -999;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
