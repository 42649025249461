<i18n>
{
  "en": {
    "title1": "SPECIAL RECOGNITION AS COURT-APPOINTED LAWYER",
    "conference1": "XV LEGAL-PENITENTIARY MEETING ",
    "conference2": "SEMINARS ON BANKING AND FINANCIAL PRODUCTS AND SERVICES",
    "course1": "CE 15 ACCOUNTING AND ADMINISTRATIVE MANAGEMENT FOR AUDITING",
    "course2": "CE 16 ACCOUNTING AND ADMINISTRATIVE MANAGEMENT FOR AUDITING",
    "course3": "CE 17 ACCOUNTING AND ADMINISTRATIVE MANAGEMENT FOR AUDITING",
    "course4": "PAST, PRESENT AND FUTURE OF THE PENITENTIARY SYSTEM",
    "course5": "TEAM MANAGEMENT",
    "course6": "MANAGEMENT ASSISTANT",
    "course7": "PROTECTION ORDER, COMPREHENSIVE LAW",
    "course8": "PRACTICAL ASPECTS OF LEGAL ASSISTANCE TO VICTIMS OF GENDER VIOLENCE",
    "course9": "RIGHT TO SECOND CHANCE FOR INDIVIDUALS",
    "course10": "INHERITANCE LAW",
    "sectionTitles": "Recognitions and mentions",
    "sectionCourses": "Courses",
    "sectionConferences": "Conferences and workshops"
  },
  "es": {
    "title1": "ESPECIAL RECONOCIMIENTO COMO ABOGADA DE TURNO DE OFICIO",
    "conference1": "XV ENCUENTROS JURÍDICO-PENITENCIARIOS",
    "conference2": "JORNADAS SOBRE PRODUCTOS Y SERVICIOS BANCARIOS Y FINANCIEROS",
    "course1": "CE 15 GESTIÓN CONTABLE Y GESTIÓN ADMINISTRATIVA PARA AUDITORÍA",
    "course2": "CE 16 GESTIÓN CONTABLE Y GESTIÓN ADMINISTRATIVA PARA AUDITORÍA",
    "course3": "CE 17 GESTIÓN CONTABLE Y GESTIÓN ADMINISTRATIVA PARA AUDITORÍA",
    "course4": "PASADO, PRESENTE Y FUTURO DEL SISTEMA PENITENCIARIO",
    "course5": "DIRECCIÓN DE EQUIPOS",
    "course6": "ADJUNTO A LA DIRECCIÓN",
    "course7": "ORDEN DE PROTECCIÓN, LEY INTEGRAL",
    "course8": "ASPECTOS PRÁCTICOS DE LA ASISTENCIA JURÍDICA A VÍCTIMAS DE VIOLENCIA DE GÉNERO",
    "course9": "DERECHO A LA SEGUNDA OPORTUNIDAD DE LAS PERSONAS FÍSICAS",
    "course10": "DERECHO DE SUCESIONES",
    "sectionTitles": "Reconocimientos y menciones",
    "sectionConferences": "Conferencias y jornadas",
    "sectionCourses": "Cursos"
  }
}
</i18n>
<template>
  <div>
    <div v-for="structure in structures" :key="structure.title">
      <div class="name">{{$t(structure.title)}}</div>
      <div class="d-flex flex-wrap align-items-center justify-content-center">
        <image-with-text
          v-for="index in structure.numberOfItems"
          :key="index"
          :image-path="imagePath"
          :width="500"
          :text="$t(`${structure.itemPrefix}${index}`)"
          :top="top"
          :text-style="textStyle"
          class="m-4"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Components } from '@core/helpers/decorators';
import Vue from 'vue';
import Component from 'vue-class-component';
import ImageWithText from '../components/ImageWithText.vue';

const IMAGE_PATH = require('../../../assets/susana-diploma-empty.svg');

@Component
@Components({ ImageWithText })
export default class Certifications extends Vue {
  imagePath = IMAGE_PATH;

  structures = [
    {
      title: 'sectionTitles',
      numberOfItems: 1,
      itemPrefix: 'title',
    },
    {
      title: 'sectionCourses',
      numberOfItems: 10,
      itemPrefix: 'course',
    },
    {
      title: 'sectionConferences',
      numberOfItems: 2,
      itemPrefix: 'conference',
    },
  ];

  get textStyle() {
    let commonStyle = 'padding-right: 30px; padding-left: 30px; font-weight: 700; font-family: "Montserrat", sans-serif;';
    if (window.innerWidth <= 600) {
      commonStyle += 'font-size: 1.2em;';
    } else {
      commonStyle += 'font-size: 1.6em;';
    }
    return commonStyle;
  }

  get top() {
    if (window.innerWidth <= 600) {
      return 50;
    }
    return 70;
  }
}
</script>
<style scoped>
.name {
  text-align: center;
  word-wrap: break-word;
  padding-right: 5px;
  padding-left: 5px;
}
</style>
