<template functional>
    <div @click="listeners['go-to'](props.route)"
    class="sidebar-button header-item" :class="[data.staticClass, data.class] || ''">{{props.name}}</div>
</template>
<script>
export default {
  props: {
    name: String,
    route: Object,
  }
};
</script>
<style scoped>
.sidebar-button{
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: 20px;
}
.selected {
  text-decoration: underline;
  text-decoration-color: rgb(81 18 96 / 100%);
  text-underline-offset: 5px;
}
</style>
