<i18n>
{
    "en": {
        "title": "Contacto",
        "address": "Veinte de Febrero Street 4, 5th floor B, Valladolid, 47001",
        "prefix": "+34",
        "telephone": "983 84 03 43",
        "mobile": "605 59 52 60",
        "email": "susana.ayala@hotmail.com",
        "email-subject": "Legal consult"
    },
    "es": {
        "title": "Contacto",
        "address": "Calle Veinte de Febrero Nº 4, 5ºB, Valladolid, 47001",
        "prefix": "+34",
        "telephone": "983 84 03 43",
        "mobile": "605 59 52 60",
        "email": "susana.ayala@hotmail.com",
        "email-subject": "Consulta legal"
    }
}
</i18n>
<template>
  <div class="d-flex flex-column">
    <div class="name">{{ $t("title") }}</div>
    <div
      class="
        d-flex
        flex-column flex-md-row
        justify-content-around
        align-items-center align-items-md-start
        flex-grow-1
        pt-0 pt-md-5
      "
    >
      <div class="text-container p-2 d-flex flex-column align-items-start">
        <div class="block-text-contact">
          <a
            :href="'//maps.google.com/maps?width=100%25&amp;height=100%&amp;hl=es&amp;q=Calle%2020%20de%20Febrero%205,%205%C2%BAB,%20Valladolid+(Susana%20Garc%C3%ADa%20Ayala%20Abogados)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;'"
            target="_blank"
            class="pr-2"
            ><b-icon icon="map" color="black" />
          </a>
          {{ $t("address") }}
        </div>
        <div class="block-text-contact">
          <a :href="phoneLink" class="pr-2"
            ><b-icon icon="telephone" color="black"
          /></a>
          {{ $t("telephone") }}
        </div>
        <div class="block-text-contact">
          <a :href="mobileLink" class="pr-2"
            ><b-icon icon="telephone" color="black"
          /></a>
          {{ $t("mobile") }}
        </div>

        <div class="block-text-contact">
          <a :href="mailLink" class="pr-2"
            ><b-icon icon="envelope" color="black"
          /></a>
          {{ $t("email") }}
        </div>
      </div>
      <div class="map-container">
        <maps class="map" />
      </div>
    </div>
  </div>
</template>

<script>
import Component from 'vue-class-component';
import Vue from 'vue';
import { Components } from '@core/helpers/decorators';
import Maps from '@main/components/Maps.vue';

@Component
@Components({ Maps })
export default class Contact extends Vue {
  get phoneLink() {
    return `tel:${this.$t('prefix')}${this.$t('telephone')}`;
  }

  get mobileLink() {
    return `tel:${this.$t('prefix')}${this.$t('mobile')}`;
  }

  get mailLink() {
    return `mailto:${this.$t('email')}?Subject=${this.$t('email-subject')}`;
  }
}
</script>
<style scoped>
.text-container {
  border: solid 2px rgba(var(--main-color), 0.7);
  background: white;
}
.map {
  width: 100%;
  height: 100%;
}
.map-container {
  width: 50%;
  max-height: 50vh;
  position: relative;
  border: solid 2px rgba(var(--main-color), 0.7);
}
.map {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.map-container:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.block-text-contact {
  font-weight: 700;
}
@media screen and (max-width: 640px) {
  .map-container {
    position: relative;
    max-width: 95%;
    width: 70%;
  }
}
</style>
