<i18n>
{
    "en": {
        "title": "About us",
        "box1_title": "CAREER",
        "box1_text": "Susana Ayala Díez is a lawyer and mediator, with a law degree from the University of Valladolid. She has been practicing law since 1994, and has more than 25 years of experience. She is the member 1938 of the Valladolid Lawyers Association.",
        "box2_title": "MEDIATION",
        "box2_text": "She has a Master's Degree in Civil and Commercial Family Mediation. Mediator specialized in intrajudicial mediation in family law and mediation in civil and commercial law.",
        "box3_title": "PARTICIPATES",
        "box3_text": "Secretary of the Board of Directors of the Mediation Group of the Valladolid Bar Association (AMICAVA.\n\nMember of the Association of Women Lawyers of Valladolid."
    },
    "es": {
        "title": "Sobre nosotros",
        "box1_title": "TRAYECTORIA",
        "box1_text": "Susana Ayala Díez es una abogada y mediadora, licenciada en derecho por la Universidad de Valladolid. Lleva en ejercicio profesional desde 1994, y cuenta con más de 25 años de experiencia. Es la colegiada Nº 1938 del Ilustre Colegio de Abogados de Valladolid",
        "box2_title": "MEDIACIÓN",
        "box2_text": "Cuenta con un Máster en Mediación Familiar Civil y Mercantil. Mediadora especializada en mediación intrajudicial en derecho de familia y en mediación en derecho civil y mercantil.",
        "box3_title": "FORMA PARTE",
        "box3_text": "Secretaria de la Junta Directiva de la Agrupación de Mediación del Colegio de Abogados de Valladolid (AMICAVA).\n\nPerteneciente a la Asociación de Mujeres Abogadas de Derecho de Valladolid."
    }
}
</i18n>
<template>
  <div class="d-flex flex-column align-content-start">
            <div class="name">{{$t('title')}}</div>
            <box-maker :boxes="boxes"
            />
  </div>
</template>

<script>
import Component from 'vue-class-component';
import Vue from 'vue';
import { Components } from '@core/helpers/decorators';
import BoxMaker from '../components/BoxMaker.vue';

@Component
@Components({ BoxMaker })
export default class About extends Vue {
  get boxes() {
    // eslint-disable-next-line no-underscore-dangle
    const { messages } = this._i18n;
    const translationFunction = this.$t.bind(this);
    return BoxMaker.getBoxes(messages, translationFunction);
  }
}
</script>
